import clsx from "clsx";
import type { ReactNode, JSX } from "react";
import React from "react";
import { Paragraph } from "~/components/ui/typography";
import { type Props as TagProps, Tag } from "~/components/ui/tag";
import { BlockContent } from "../block-content";
import { isGradientColor } from "~/utils/misc";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import { DEFAULT_LOGO_HEIGHT } from "~/utils/media-height";
import { Icon as IconComponent } from "~/components/ui/icons";
import { ConditionalLink } from "./link";

interface Props {
	children: React.ReactNode;
	borderTop?: boolean;
	borderTopColor?: string;
	className?: string;
	as?: React.ElementType;
	href?: string;
	trackingPosition?: string;
	hasLinkInside?: boolean;
}

export function Card({
	children,
	borderTop,
	borderTopColor = "rgb(var(--color-orange-60))",
	className,
	href,
	trackingPosition,
	hasLinkInside = false,
}: Props) {
	const hasLink = Boolean(href);

	return (
		<div
			className={clsx("group size-full rounded-md", {
				"group/arrow cursor-pointer bg-white transition-shadow focus-within:shadow-md hover:shadow-md dark:bg-transparent":
					hasLink || hasLinkInside,
			})}
		>
			<ConditionalLink
				to={href || ""}
				trackingPosition={trackingPosition}
				condition={hasLink}
				className={clsx(
					"border-stroke group relative flex size-full flex-col rounded-md border bg-white dark:bg-transparent",
					className,
					{ "overflow-hidden": !borderTop },
					{ "rounded-t-none": borderTop }
				)}
			>
				{borderTop ? (
					<div
						style={{
							backgroundColor: !isGradientColor(borderTopColor)
								? borderTopColor
								: undefined,
							backgroundImage: isGradientColor(borderTopColor)
								? borderTopColor
								: undefined,
						}}
						className={clsx("absolute bottom-full h-1 w-full rounded-t-md")}
					></div>
				) : null}

				{children}
			</ConditionalLink>
		</div>
	);
}

function Cover({
	imgProps,
	aspectRatio = "aspect-card-cover",
	imageClassName,
	className,
}: {
	imgProps: ImageProps;
	aspectRatio?: string;
	imageClassName?: string;
	className?: string;
}) {
	return (
		<div className={clsx("overflow-hidden bg-grey-5", className, aspectRatio)}>
			<Image
				className={clsx("size-full object-cover", imageClassName)}
				{...imgProps}
			/>
		</div>
	);
}

function Icon({
	imgProps,
	className,
}: {
	imgProps: ImageProps;
	className?: string;
}) {
	return (
		<div className={clsx("mb-5", className)}>
			<Image className="h-[46px]" {...imgProps} />
		</div>
	);
}

function People({
	imgProps,
	className,
	name,
	jobTitle,
}: {
	imgProps?: ImageProps;
	className?: string;
	name?: string;
	jobTitle?: string;
}) {
	return (
		<div className={clsx("flex gap-3 pt-3", className)}>
			{imgProps ? (
				<Image
					className="size-layout5 overflow-hidden rounded-full object-cover"
					{...imgProps}
				/>
			) : null}
			<div className="flex flex-col">
				<Paragraph fontWeight="font-medium">{name}</Paragraph>
				<Paragraph size="body-small" color="secondary">
					{jobTitle}
				</Paragraph>
			</div>
		</div>
	);
}

function Logo({
	imgProps,
	className,
	logoHeight,
}: {
	imgProps: ImageProps;
	className?: string;
	logoHeight?: number;
}) {
	return (
		<div className={clsx("mb-5", className)}>
			<Image
				style={{
					height: `${logoHeight ? logoHeight : DEFAULT_LOGO_HEIGHT}px`,
				}}
				{...imgProps}
			/>
		</div>
	);
}

function Outline({
	children,
	extra,
	className,
}: {
	children: string;
	extra?: React.ReactNode;
	className?: string;
}) {
	return (
		<div
			className={clsx(
				"border-stroke bg-secondary border-b px-5 py-3",
				className
			)}
		>
			<div className="flex items-center gap-3">
				<Paragraph size="overline" color="tagline">
					<span suppressHydrationWarning>{children}</span>
				</Paragraph>
				{extra}
			</div>
		</div>
	);
}

function Content({
	children,
	className,
}: {
	children?: React.ReactNode;
	className?: string;
}) {
	return <div className={clsx("flex-1 p-6", className)}>{children}</div>;
}

function ChipText({
	children,
	containerClassName,
	...rest
}: TagProps & { containerClassName?: string }) {
	return (
		<div className={clsx("mb-5", containerClassName)}>
			<Tag {...rest}>{children}</Tag>
		</div>
	);
}

function Title({
	children,
	className,
}: {
	children: string | React.ReactNode;
	className?: string;
}) {
	return (
		<div className={clsx("mb-3", className)}>
			<Paragraph fontWeight="font-semibold" as="h3">
				{children}
			</Paragraph>
		</div>
	);
}

function Subtitle({
	children,
	className,
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
	className?: string;
}) {
	return (
		<div className={clsx(className)}>
			{typeof children === "string" ? (
				<Paragraph color="secondary" size="body-small">
					{children}
				</Paragraph>
			) : (
				<BlockContent
					value={children}
					blockOverrides={{
						normal: ({ children }) => {
							if (
								Array.isArray(children) &&
								children.length === 1 &&
								children[0] === ""
							) {
								return <br />;
							}
							return (
								<Paragraph color="secondary" size="body-small">
									{children}
								</Paragraph>
							);
						},
					}}
					paragraphClassName="!text-sm"
				/>
			)}
		</div>
	);
}

function Extra({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<div className={clsx("px-6 pb-6 last:pt-0", className)}>{children}</div>
	);
}

const GhostArrowCTA = ({
	children,
	className,
	size,
	customIcon,
}: {
	children: ReactNode;
	className?: string;
	size?: "dense";
	customIcon?: JSX.Element | null;
}) => {
	return (
		<div
			className={clsx(
				"text-primary inline-flex w-fit items-center gap-2 rounded border border-transparent bg-transparent font-medium no-underline decoration-theme-primary decoration-1 underline-offset-8 duration-200 ease-in-out group-hover:underline",
				className,
				{
					"text-xs md:text-sm": size === "dense",
				}
			)}
		>
			{children}

			<IconComponent
				name={customIcon ? "external-link" : "arrow-right"}
				className="transition-all group-hover/arrow:translate-x-2"
				color="cta"
			/>
		</div>
	);
};

Card.Cover = Cover;
Card.Icon = Icon;
Card.People = People;
Card.Logo = Logo;
Card.Outline = Outline;
Card.ChipText = ChipText;
Card.Title = Title;
Card.Subtitle = Subtitle;
Card.Content = Content;
Card.Extra = Extra;
Card.GhostArrowCTA = GhostArrowCTA;
